export function makeLrsAgentForUser (userEmail, userFullname) {
  const email = userEmail.includes('@') ? userEmail : `${userEmail}@madeupemail.com`
  return `{"objectType":"Agent","mbox":"mailto:${email}","name":"${userFullname.toUpperCase()}"}`
}

export function makeCourseLinkForUser (userEmail, userFullname, courseName, courseVersion) {
  const email = userEmail.includes('@') ? userEmail : `${userEmail}@madeupemail.com`
  const lrsActor = `{"name": ["${userFullname.toUpperCase()}"], "mbox": ["mailto:${email}"]}`
  return `${process.env.VUE_APP_COURSE_LINK}/${courseName}/${courseVersion}/index.html` +
    `?tok=${process.env.VUE_APP_CDN_TOKEN}` +
    `&endpoint=${encodeURIComponent(process.env.VUE_APP_LRS_XAPI)}` +
    `&auth=${encodeURIComponent('Basic ' + process.env.VUE_APP_LRS_BASIC_AUTH)}` +
    `&actor=${encodeURIComponent(lrsActor)}`
}
